<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">In vendita al privato </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Parco Auto</b-breadcrumb-item>
            <b-breadcrumb-item active> In vendita al privato</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-table
        class="mt-2"
        :fields="fields"
        :items="carProvider"
        :busy="loading"
        show-empty
        responsive
        striped
        @sort-changed="sortTable"
      >
         <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key == 'opzioni' ? '800px' : '180px' }"
          >
         </template>
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle auto...</strong>
          </div>
        </template>
        <template #cell(id)="data">
          {{ data.item.id_auto }}
        </template>
        <template #cell(marca)="data">
          {{ data.item.marca }}  
        </template>
        <template #cell(modello)="data">
          {{ data.item.modello }}
        </template>
        <template #cell(versione)="data">
          {{ data.item.versione }}
        </template>
        <template #cell(anno)="data">
          {{ data.item.immatricolazione_anno }}
        </template>
        <template #cell(km)="data">
          {{ data.item.chilometraggio }}
        </template>
        <template #empty>
          <div class="text-center">
            <h5 class="text-primary-light my-2">Non ci sono auto da mostrare</h5>
          </div>
        </template>
        <template #cell(opzioni)>
          
          <span class="mr-2 text-nowrap">
            <b-button class="mr-2" variant="primary" @click="$router.push({name: 'seller_carfleet_estimate_customer'})">
               Crea Preventivo
            </b-button>

            <b-button variant="primary">
               Crea Contratto
            </b-button>
          </span>

        </template>
      </b-table>
    </b-card>

    <!-- Modali -->

    <vehicle-price-set-modal ref="vehicle_price_set_modal"/>
    <vehicle-costs-edit-modal ref="vehicle_costs_edit_modal" />
    <vehicle-photo-edit-modal ref="vehicle_photo_edit_modal"/>
    <vehicle-photo-examination-edit-modal ref="vehicle_photo_examination_edit_modal" />
    <vehicle-documents-modal ref='vehicle_documents_modal'/>
    <vehicle-photo-modal ref="vehicle_photo_modal" />
    <vehicle-documents-edit-modal ref="vehicle_documents_edit_modal" />
    <vehicle-status-edit-modal ref="vehicle_status_edit_modal" />
    <vehicle-location-edit-modal ref="vehicle_location_edit_modal" />
    <vehicle-reservation-list-modal ref="vehicle_reservation_list_modal" />

  </section>
</template>

<script>
import {
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardTitle,
  BIcon,
  BDropdown,
  BDropdownItem,
  BTable,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BSpinner,
} from "bootstrap-vue";

import {  
  VehiclePriceSetModal,
  VehicleCostsEditModal,
  VehicleDocumentsModal,
  VehicleDocumentsEditModal,
  VehiclePhotoEditModal,
  VehiclePhotoExaminationEditModal,
  VehiclePhotoModal,
  VehicleStatusEditModal,
  VehicleLocationEditModal,
  VehicleReservationListModal,
} from '@/components/modals'

import { Requests } from "@/api";

export default {
  components: {
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardTitle,
    BIcon,
    BDropdown,
    BDropdownItem,
    BTable,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    VehiclePriceSetModal,
    VehicleCostsEditModal,
    VehicleDocumentsModal,
    VehicleDocumentsEditModal,
    VehiclePhotoModal,
    VehiclePhotoExaminationModal,
    VehiclePhotoEditModal,
    VehiclePhotoExaminationEditModal,
    VehicleStatusEditModal,
    VehicleLocationEditModal,
    VehicleReservationListModal,
  },

  data: () => ({
    fields: [
      { key: "id", sortable: true },
      { key: "marca", sortable: true },
      { key: "modello", sortable: true},
      { key: "versione", sortable: true},
      { key: "anno", sortable: true },
      { key: "km", sortable: true },
      { key: "targa", sortable: true },
      { key: "telaio", sortable: true },
      { key: "colore", sortable: true },
      "opzioni",
    ],

    items: [],

    //Helpers

    loading: false,
  }),

  methods: {
    async getAutoOnSale(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("type", "seller_privateprice")
      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getAutoOnSale();
        this.items = response;
      } catch {
        console.debug(err);
      }

      this.loading = false;
    },

    async carProvider() {
      await this.getAutoOnSale();
      return this.items.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>

<style>
.b-dropdown button {
  padding: 0;
}
</style>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
</style>
